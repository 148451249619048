<template>
  <CompaniesForm
    @save="updateAgency"
    :company="company"
    :type="'edit'"
  />
</template>

<script>
import beforeLeaveRouteModal from '@/mixins/beforeLeaveRouteModal.js'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'
export default {
  components: {
    CompaniesForm: () => import('./CompaniesForm'),
  },
  mixins: [beforeLeaveRouteModal, checkProfileRoutePermission],
  data: () => ({
    company: {},
    askToLeave: true,
  }),
  methods: {
    getCurrentCompany() {
      this.$api
        .get(`companies/${this.$route.params.id}`)
        .then((res) => {
          this.company = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    updateAgency() {
      this.askToLeave = false
      this.$api
        .post(`companies/update/${this.$route.params.id}`, this.company)
        .then(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'success',
            text: 'Empresa atualizada com sucesso',
            confirm: () => {
              this.$router.push({ name: 'agencies' })
            },
          })
        })
        .catch(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: 'Ops! Falha ao tentar atualizar esta empresa',
            caption: 'Verifique se os campos estão preenchidos corretamente.',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
    },
  },
  created() {
    this.getCurrentCompany()
  },
  beforeRouteLeave(to, from, next) {
    if (this.askToLeave) {
      this.openModal(
        () => {
          next()
        }, // Caso o modal seja confirmado
        () => {
          next(false)
        } // Caso o modal seja negado
      )
    } else {
      next()
    }
  },
}
</script>
